<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-spinner
      v-if="loading"
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
    />
    <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          cols="12"
          xl="8"
          md="7"
        >
          <b-row>
            <b-col
              cols="12"
              xl="12"
              md="12"
            >
              <b-card no-body>
                <b-card-header class="pb-50">
                  <h5>Editar Versión de Curso</h5>
                </b-card-header>
                <b-card-body>
                  <b-row>

                    <!-- Col: Invoice To -->
                    <b-col
                      cols="12"
                      md="7"
                      xl="7"
                    >

                      <!-- Select Client -->
                      <validation-provider
                        #default="validationContext"
                        name="Curso"
                        rules="required"
                      >
                        <b-form-group
                          label="Seleccione Curso"
                          label-for="curso"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="cursoVersionData.curso"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="cursos"
                            input-id="curso"
                            :clearable="false"
                            label="nombre"
                            :reduce="val => val.id"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="5"
                      xl="5"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Modalidad"
                        rules="required"
                      >
                        <b-form-group
                          label="Modalidad"
                          label-for="modalidad"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="cursoVersionData.modalidad"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            :options="modalidades"
                            label="nombre"
                            variant="primary"
                            input-id="modalidad"
                            :reduce="val => val.id"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="12"
                      xl="12"
                    >
                      <!-- Nombre de la versión del Curso -->
                      <validation-provider
                        #default="validationContext"
                        name="Nombre de la Version del Curso"
                        rules="required"
                      >
                        <b-form-group
                          label="Nombre de la Version del Curso"
                          label-for="nombre-version-curso"
                        >
                          <b-form-input
                            id="nombre-version-curso"
                            v-model="cursoVersionData.nombre"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Curso de Vue.js + Node.js v2"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="8"
                      xl="8"
                    >
                      <!-- Nombre de la versión del Curso -->
                      <validation-provider
                        #default="validationContext"
                        name="Slug URL"
                        rules="required"
                      >
                        <b-form-group
                          label="Slug URL"
                          label-for="slug-url"
                        >
                          <b-input-group>
                            <b-form-input
                              id="slug-url"
                              v-model="slug"
                              :state="getValidationState(validationContext)"
                              trim
                              placeholder="curso-vuejs-node-v2"
                              :disabled="estadoslug"
                            />
                            <b-input-group-append>
                              <b-button
                                variant="outline-primary"
                                @click="estadoslug=!estadoslug"
                              >
                                <feather-icon
                                  icon="EditIcon"
                                  class="cursor-pointer"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      xl="4"
                    >
                      <b-form-group
                        label="Detalle Version Curso"
                        label-for="detalle-version-curso"
                      >
                        <b-form-input
                          id="detalle-version-curso"
                          v-model="cursoVersionData.detalle"
                          trim
                          placeholder="1ra. Versión"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="12"
                      xl="12"
                    >
                      <b-form-group
                        label="Resumen"
                        label-for="resumen"
                      >
                        <b-form-input
                          id="resumen"
                          v-model="cursoVersionData.resumen"
                          trim
                          placeholder="128 caracteres"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              xl="12"
              md="12"
            >
              <b-card no-body>
                <b-card-header class="pb-50">
                  <h5>Precios por Paises:</h5>
                </b-card-header>
                <b-card-body>
                  <div ref="precios">

                    <!-- Row Loop -->
                    <b-row
                      v-for="(item, index) in cursoVersionData.precio_curso"
                      :id="index"
                      :key="index"
                      ref="row_precio"
                    >

                      <!-- Item Name -->
                      <b-col md="6">
                        <validation-provider
                          #default="validationContext"
                          name="Pais"
                          rules="required"
                        >
                          <b-form-group
                            label="Pais"
                            label-for="pais"
                            :state="getValidationState(validationContext)"
                          >
                            <v-select
                              v-if="paises"
                              v-model="item.pais"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="paises"
                              :clearable="false"
                              :reduce="val => val.value"
                              input-id="pais"
                              class="select-size-sm"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Cost -->
                      <b-col md="4">
                        <validation-provider
                          #default="validationContext"
                          name="Precio"
                          rules="required"
                        >
                          <b-form-group
                            label="Precio"
                            label-for="precio"
                          >
                            <b-form-input
                              id="precio"
                              v-model.number="item.costo"
                              type="number"
                              placeholder="890.90"
                              step="0.01"
                              input-id="precio"
                              size="sm"
                              :state="getValidationState(validationContext)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="2"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-1"
                          size="sm"
                          @click="removeItemPrecio(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="addPrecioNuevo"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                      <span>Agregar Precio</span>
                    </b-button>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              xl="12"
              md="12"
            >
              <b-card no-body>
                <b-card-header class="pb-50">
                  <h5>Descripción General</h5>
                </b-card-header>
                <b-card-body>
                  <quill-editor
                    v-model="cursoVersionData.descripcion_general"
                  />
                </b-card-body>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              xl="12"
              md="12"
            >
              <b-card no-body>
                <b-card-header class="pb-50">
                  <h5>Detalles Versión Curso</h5>
                </b-card-header>
                <b-card-body>
                  <b-tabs
                    fill
                    pills
                  >
                    <b-tab
                      title="Docentes"
                      active
                    >
                      <b-card-text>
                        <div
                          v-for="(per,index) in docentesSeleccionados"
                          :key="index"
                          class="employee-task d-flex justify-content-between align-items-center"
                        >
                          <b-media no-body>
                            <b-media-aside class="mr-75">
                              <b-avatar
                                rounded
                                size="42"
                                :src="per.avatar"
                              />
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h6 class="mb-0">
                                {{ `${per.nombres} ${per.paterno}` }}
                              </h6>
                              <small>{{ per.documento_identidad }}</small>
                            </b-media-body>
                          </b-media>
                          <div class="d-flex align-items-center">
                            <small class="text-muted mr-75">{{ per.ciudad }}</small>
                            <b-button
                              variant="outline-danger"
                              @click="quitarDocente(index)"
                            >
                              <feather-icon
                                icon="XIcon"
                                class="cursor-pointer"
                              />
                              <span>quitar</span>
                            </b-button>
                          </div>
                        </div>
                        <b-form-group
                          label="Buscar y Seleccionar como Docente"
                          label-for="docente"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="searchValuePersona"
                              placeholder="Buscar y Seleccionar como Docente"
                              debounce="500"
                            />
                          </b-input-group>

                        </b-form-group>
                        <b-table
                          responsive="sm"
                          :items="personas"
                          :fields="columnas_docentes"
                        >
                          <template #cell(accion)="data">
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              @click="addNuevoDocente(data.item)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                class="cursor-pointer"
                              />
                              <span>seleccionar</span>
                            </b-button>
                          </template>
                        </b-table>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Habilidades">
                      <b-card-text>
                        <div ref="ref_habilidades">

                          <!-- Row Loop -->
                          <b-row
                            v-for="(habil, index) in habilidades"
                            :id="index"
                            :key="index"
                            ref="row_habilidades"
                          >
                            <!-- Cost -->
                            <b-col md="12">
                              <validation-provider
                                #default="validationContext"
                                name="Habilidad"
                                rules="required"
                              >
                                <b-form-group
                                  label="Habilidad"
                                  label-for="habilidad"
                                >
                                  <b-input-group>
                                    <b-form-input
                                      id="habilidad"
                                      v-model="cursoVersionData.habilidades[index]"
                                      placeholder="Lo que aprenderás"
                                      input-id="habilidad"
                                      :state="getValidationState(validationContext)"
                                    />
                                    <b-input-group-append>
                                      <b-button
                                        variant="outline-danger"
                                        @click="removeItemHabilidad(index)"
                                      >
                                        <feather-icon
                                          icon="XIcon"
                                          class="cursor-pointer"
                                        />
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col cols="12">
                              <hr>
                            </b-col>
                          </b-row>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="addHabilidadNuevo"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span>Agregar Habilidad</span>
                          </b-button>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Requisitos">
                      <b-card-text>
                        <div ref="ref_requisitos">

                          <!-- Row Loop -->
                          <b-row
                            v-for="(requisito, index) in requisitos"
                            :id="index"
                            :key="index"
                            ref="row_requisitos"
                          >
                            <!-- Cost -->
                            <b-col md="12">
                              <validation-provider
                                #default="validationContext"
                                name="Requsito"
                                rules="required"
                              >
                                <b-form-group
                                  label="Requsito"
                                  label-for="requisito"
                                >
                                  <b-input-group>
                                    <b-form-input
                                      id="requisito"
                                      v-model="cursoVersionData.requisitos[index]"
                                      placeholder="Qué necesitas"
                                      input-id="requisito"
                                      :state="getValidationState(validationContext)"
                                    />
                                    <b-input-group-append>
                                      <b-button
                                        variant="outline-danger"
                                        @click="removeItemRequisito(index)"
                                      >
                                        <feather-icon
                                          icon="XIcon"
                                          class="cursor-pointer"
                                        />
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col cols="12">
                              <hr>
                            </b-col>
                          </b-row>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="addRequisitoNuevo"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span>Agregar Requisito</span>
                          </b-button>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Tag"
                    >
                      <b-card-text>
                        <b-form-group
                          label="Seleccione o buscar Tags"
                          label-for="tags-cv"
                        >
                          <v-select
                            v-if="tags"
                            v-model="cursoVersionData.tags"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="tags"
                            :clearable="false"
                            :reduce="val => val.value"
                            input-id="tags-cv"
                            multiple
                          />
                        </b-form-group>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Beneficio">
                      <b-card-text>
                        <b-form-group
                          label="Seleccione o buscar Beneficios"
                          label-for="beneficios-cv"
                        >
                          <v-select
                            v-if="beneficios"
                            v-model="cursoVersionData.beneficios"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="beneficios"
                            :clearable="false"
                            :reduce="val => val.value"
                            input-id="beneficios-cv"
                            multiple
                          />
                        </b-form-group>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="faq">
                      <b-card-text>
                        <b-form-group
                          label="Seleccione o buscar Preguntas y Respuestas"
                          label-for="faq-cv"
                        >
                          <v-select
                            v-if="faqs"
                            v-model="cursoVersionData.faqs"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="faqs"
                            :clearable="false"
                            :reduce="val => val.value"
                            input-id="faq-cv"
                            multiple
                          />
                        </b-form-group>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <TemarioCursoVersion />
        </b-col>
        <b-col
          cols="12"
          xl="4"
          md="5"
        >
          <b-card no-body>
            <b-card-header class="pb-50">
              <h5>Detalles Banner (publicación)</h5>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col cols="7">
                  <b-form-group
                    label="Estado Publicación"
                    label-for="estado-publicado"
                  >
                    <v-select
                      v-model="cursoVersionData.publicado"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="[{valor: false, label: 'Borrador'}, {valor: true, label: 'Publico(visible)'}]"
                      :reduce="val => val.valor"
                      :clearable="false"
                      input-id="estado-publicado"
                      class="select-size-sm"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="5">
                  <b-form-group
                    label="Mostrar Costo"
                    label-for="mos-costo"
                  >
                    <b-form-checkbox
                      v-model="cursoVersionData.mostrar_costo"
                    >
                      {{ (!cursoVersionData.mostrar_costo)?'No Visible':'Visible' }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
                label="Fecha Inicio"
                label-for="fecha-inicio"
              >
                <b-form-datepicker
                  id="fecha-inicio"
                  v-model="cursoVersionData.fecha_inicio"
                  class="mb-1"
                  size="sm"
                  placeholder="Sin fecha seleccionada"
                  locale="es"
                />
              </b-form-group>

              <b-form-group
                label="Fecha Fin"
                label-for="fecha-fin"
              >
                <b-form-datepicker
                  id="fecha-fin"
                  v-model="cursoVersionData.fecha_fin"
                  size="sm"
                  class="mb-1"
                  placeholder="Sin fecha seleccionada"
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                  locale="es"
                />
              </b-form-group>
              <b-row>
                <b-col cols="8">
                  <b-form-group
                    label="Duración"
                    label-for="duracion"
                  >
                    <b-form-input
                      id="duracion"
                      v-model="cursoVersionData.duracion"
                      size="sm"
                      trim
                      placeholder="3 semanas"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Cupos"
                    label-for="cupos"
                  >
                    <b-form-input
                      id="cupos"
                      v-model.number="cursoVersionData.cupos"
                      size="sm"
                      trim
                      type="number"
                      placeholder="10"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Carga Horaria"
                    label-for="carga_horaria"
                  >
                    <b-form-input
                      id="carga_horaria"
                      v-model.number="cursoVersionData.carga_horaria"
                      size="sm"
                      trim
                      type="number"
                      step="0.1"
                      placeholder="22.5"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Descuento(%)"
                    label-for="descuento"
                  >
                    <b-form-input
                      id="descuento"
                      v-model.number="cursoVersionData.descuento"
                      size="sm"
                      type="number"
                      trim
                      placeholder="5"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                label="Formato"
                label-for="formato"
              >
                <b-form-input
                  id="formato"
                  v-model="cursoVersionData.formato"
                  size="sm"
                  trim
                  placeholder="100% práctico"
                />
              </b-form-group>

              <b-form-group
                label="Asesoramiento"
                label-for="asesoramiento"
              >
                <b-form-input
                  id="asesoramiento"
                  v-model="cursoVersionData.asesoramiento"
                  size="sm"
                  trim
                  placeholder="asesoramiento en tus propios proyectos"
                />
              </b-form-group>

              <b-form-group
                label="Certificado"
                label-for="certificado"
              >
                <b-form-input
                  id="certificado"
                  v-model="cursoVersionData.certificado"
                  size="sm"
                  trim
                  placeholder="Se entregará un certificado de participación"
                />
              </b-form-group>

              <validation-provider
                #default="validationContext"
                name="Metodos de Pago"
                rules="required"
              >
                <b-form-group
                  label="Metodos de Pago"
                  label-for="metodo_pago"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="cursoVersionData.metodo_pago"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="metodopagos"
                    :reduce="val => val.id"
                    :clearable="true"
                    multiple
                    input-id="metodo_pago"
                    class="select-size-sm"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-card-body>
          </b-card>
          <b-card no-body>
            <b-card-header class="pb-50">
              <h5>Horarios y Duración</h5>
            </b-card-header>
            <b-card-body>
              <div
                ref="horario"
                class="repeater-horario"
              >

                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in cursoVersionData.hora"
                  :id="index"
                  :key="index"
                  ref="row"
                >

                  <!-- Item Name -->
                  <b-col md="5">
                    <validation-provider
                      #default="validationContext"
                      name="Pais"
                      rules="required"
                    >
                      <b-form-group
                        label="Pais"
                        label-for="pais"
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          v-if="paises"
                          v-model="item.pais"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="paises"
                          :clearable="false"
                          :reduce="val => val.value"
                          input-id="pais"
                          class="select-size-sm"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Cost -->
                  <b-col md="7">
                    <validation-provider
                      #default="validationContext"
                      name="Hora"
                      rules="required"
                    >
                      <b-form-group
                        label="Hora"
                        label-for="hora"
                      >
                        <b-input-group>
                          <b-form-input
                            id="hora"
                            v-model="item.hora"
                            placeholder="20:30 - 22:00"
                            input-id="hora"
                            size="sm"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-danger"
                              size="sm"
                              @click="removeItem(index)"
                            >
                              <feather-icon
                                icon="XIcon"
                                class="cursor-pointer"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Agregar hora</span>
                </b-button>
              </div>
              <hr>
              <div ref="ref_dias">

                <!-- Row Loop -->
                <b-row
                  v-for="(dia, index) in dias"
                  :id="index"
                  :key="index"
                  ref="row_dias"
                >
                  <!-- Cost -->
                  <b-col md="12">
                    <validation-provider
                      #default="validationContext"
                      name="Dias"
                      rules="required"
                    >
                      <b-form-group
                        label="Dias"
                        label-for="dia"
                      >
                        <b-input-group>
                          <b-form-input
                            id="dia"
                            v-model="cursoVersionData.dias[index]"
                            placeholder="Lunes a Viernes"
                            input-id="dia"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-danger"
                              @click="removeItemDia(index)"
                            >
                              <feather-icon
                                icon="XIcon"
                                class="cursor-pointer"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addDiaNuevo"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Agregar Día</span>
                </b-button>
              </div>

            </b-card-body>
          </b-card>
          <!-- Action Buttons -->
          <b-card>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75"
              block
              disabled
            >
              Validar Datos
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
            >
              Previsualizar
            </b-button>

            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              block
              :disabled="disabled"
              type="submit"
            >
              Guardar
            </b-button>
            <code>
              <pre>
  {{ cursoVersionData }}
    </pre>
            </code>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardHeader, BCardBody, BButton, BForm, BFormInvalidFeedback, BFormGroup, BFormInput, BFormDatepicker, BTabs, BTab, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BTable, BMedia, BMediaAside, BAvatar, BMediaBody, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// Editor Descripcion general
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import { ref, watch } from '@vue/composition-api'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

import slugify from 'slugify'

import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import TemarioCursoVersion from './TemarioCursoVersion.vue'
import SELECT_CURSOS from '../../../../graphql/cursos/curso/SelectCursos.gql'
import SELECT_MODALIDAD from '../../../../graphql/cursos/modalidad/ListModalidad.gql'
import SELECT_METODO_PAGO from '../../../../graphql/cursos/metodo-pago/SelectMetodoPago.gql'
import SELECT_TAGS from '../../../../graphql/cursos/tag/SelectTag.gql'
import SELECT_BENEFICIOS from '../../../../graphql/cursos/beneficio/SelectBeneficio.gql'
import SELECT_FAQS from '../../../../graphql/cursos/faq/SelectFaq.gql'
import GET_PAISES from '../../../../graphql/cursos/pais/SelectPais.gql'
import FIND_PERSONAS from '../../../../graphql/usuario/FindPersona.gql'
import ADD_CURSO_VERSION from '../../../../graphql/cursos/curso/version/AddCursoVersion.gql'
import FIND_CURSO_VERSION_EDIT_ID from '../../../../graphql/cursos/curso/version/EditCursoVersion.gql'

export default {
  components: {
    TemarioCursoVersion,

    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BButton,
    BForm,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormDatepicker,
    BTabs,
    BTab,
    BTable,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // editor
    quillEditor,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      estadoslug: true,
      required,
      alphaNum,
      email,
      nextTodoId: 2,
      // habilidades: [],
      // requisitos: [],
      // dias: [],
      columnas_docentes: ['nombres', 'paterno', 'documento_identidad', 'accion'],
    }
  },
  computed: {
    slug: {
      get() {
        if (this.estadoslug) {
          return this.stringToSlug(this.cursoVersionData.nombre)
        }
        return this.stringToSlug(this.cursoVersionData.slug)
      },
      set(newValue) {
        // console.log(newValue)
        this.cursoVersionData.slug = newValue
      },
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const searchValuePersona = ref('')

    // variables auxiliares para cursoVersionData
    const docentesSeleccionados = ref([])
    const dias = ref([])
    const habilidades = ref([])
    const requisitos = ref([])

    // disabled
    const disabled = ref(false)

    // const cursoVersionId = ref('')

    const blankCursoData = {
      curso: null,
      modalidad: null,
      nombre: '',
      // detalle: '',
      // descripcion_general: '',
      slug: '',
      // fecha_inicio: '',
      // fecha_fin: '',
      // carga_horaria: 0,
      habilidades: [],
      // requisitos: null,
      // tags: null,
      // temario: null,
      docentes: [],
      // metodo_pago: null,
      // resumen: '',
      hora: [],
      // dias: '',
      // duracion: '',
      // formato: '',
      // certificado: '',
      // asesoramiento: '',
      mostrar_costo: false,
      // cupos: 0,
      precio_curso: [],
      // descuento: 0,
      // mostrar_video: false,
      // video_presentacion: null,
      // imagen_presentacion: null,
      // beneficios: null,
      // faqs: null,
      publicado: false,

    }

    const cursoVersionData = ref(JSON.parse(JSON.stringify(blankCursoData)))
    const resetCursoVersionData = () => {
      // cursoVersionData.value = JSON.parse(JSON.stringify(blankCursoData))
      // limpiado data auxiiar
      docentesSeleccionados.value = []
      dias.value = []
      habilidades.value = []
      requisitos.value = []
      disabled.value = false
    }

    /* const procesarDatos = obj => {
      // obj.metodo_pago = []
      obj.metodo_pago.forEach(mp => obj.metodo_pago.push(mp.id))
      return obj
    } */
    const { loading, result } = useQuery(FIND_CURSO_VERSION_EDIT_ID, () => ({ cursoVersionId: router.currentRoute.params.id }))
    // const curso = useResult(queryCursoV.result)
    /* onMounted(() => {
      cursoVersionId.value = router.currentRoute.params.id
      console.log(cursoVersionId.value)
      // cursoVersionData.value = prueba.value
      // cargando.value = false
    }) */

    watch([loading], () => {
      console.log('DATOS: ', cursoVersionData.value)
      cursoVersionData.value = useResult(result).value
      // cargando datos extras para edicion
      // docentes
      docentesSeleccionados.value = cursoVersionData.value.docentes
      cursoVersionData.value.docentes = []
      docentesSeleccionados.value.forEach(doc => cursoVersionData.value.docentes.push(doc.id))

      dias.value = cursoVersionData.value.dias
      habilidades.value = cursoVersionData.value.habilidades
      requisitos.value = cursoVersionData.value.requisitos
    })

    const queryCursos = useQuery(SELECT_CURSOS)
    const cursos = useResult(queryCursos.result)

    const queryModalidad = useQuery(SELECT_MODALIDAD)
    const modalidades = useResult(queryModalidad.result)

    const queryMetodoPagos = useQuery(SELECT_METODO_PAGO)
    const metodopagos = useResult(queryMetodoPagos.result)

    const queryTags = useQuery(SELECT_TAGS)
    const tags = useResult(queryTags.result)

    const queryBeneficios = useQuery(SELECT_BENEFICIOS)
    const beneficios = useResult(queryBeneficios.result)

    const queryFaq = useQuery(SELECT_FAQS)
    const faqs = useResult(queryFaq.result)

    const queryPaises = useQuery(GET_PAISES)
    const paises = useResult(queryPaises.result, null, data => data.listPais)

    const busquedaPersonas = useQuery(FIND_PERSONAS, {
      value_search: searchValuePersona,
    })
    const personas = useResult(busquedaPersonas.result)

    const { mutate: sendCursoVersion, onDone, onError } = useMutation(ADD_CURSO_VERSION, () => ({
      variables: cursoVersionData.value,
    }))

    onDone(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Version del Curso Registrado',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      resetCursoVersionData()
    })

    onError(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      disabled.value = false
    })

    const onSubmit = () => {
      // elimina las claves sin valores antes de enviar "" ej. cupos, descuentos, carga_horaria que son enteros
      Object.keys(cursoVersionData.value).forEach(key => (cursoVersionData.value[key] === '') && delete cursoVersionData.value[key])
      sendCursoVersion()
      disabled.value = true
      // emit('refetch-data')
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCursoVersionData)

    return {
      cursoVersionData,
      cursos,
      modalidades,
      metodopagos,
      paises,
      tags,
      beneficios,
      faqs,
      personas,
      docentesSeleccionados,
      dias,
      habilidades,
      requisitos,
      searchValuePersona,
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      disabled,
      loading,
    }
  },
  mounted() {
    this.initTrHeight()
    this.initTrHeightPrecio()
    this.initTrHeightHabilidad()
    this.initTrHeightRequisito()
    this.initTrHeightDia()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    window.addEventListener('resize', this.initTrHeightPrecio)
    window.addEventListener('resize', this.initTrHeightHabilidad)
    window.addEventListener('resize', this.initTrHeightRequisito)
    window.addEventListener('resize', this.initTrHeightDia)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
    window.removeEventListener('resize', this.initTrHeightPrecio)
    window.removeEventListener('resize', this.initTrHeightHabilidad)
    window.removeEventListener('resize', this.initTrHeightRequisito)
    window.removeEventListener('resize', this.initTrHeightDia)
  },
  methods: {
    nuevoValorSlug($event) {
      const inputValue = $event.currentTarget.value
      this.cursoVersionData.slug = inputValue
    },
    stringToSlug(nombre) {
      if (nombre) {
        this.cursoVersionData.slug = slugify(nombre.toLowerCase())
        return this.cursoVersionData.slug
      }
      return ''
    },
    addNuevoDocente(doc) {
      if (!this.cursoVersionData.docentes.includes(doc.id)) {
        this.docentesSeleccionados.push(doc)
        this.cursoVersionData.docentes.push(doc.id)
      }
    },
    quitarDocente(pos) {
      this.docentesSeleccionados.splice(pos, 1)
      this.cursoVersionData.docentes.splice(pos, 1)
    },
    repeateAgain() {
      this.cursoVersionData.hora.push({
        pais: '',
        hora: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.cursoVersionData.hora.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.horario.scrollHeight)
      })
    },
    // precio
    addPrecioNuevo() {
      this.cursoVersionData.precio_curso.push({
        pais: '',
        costo: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row_precio[0].offsetHeight)
      })
    },
    removeItemPrecio(index) {
      this.cursoVersionData.precio_curso.splice(index, 1)
      this.trTrimHeight(this.$refs.row_precio[0].offsetHeight)
    },
    initTrHeightPrecio() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.precios.scrollHeight)
      })
    },
    // habilidad
    addHabilidadNuevo() {
      this.habilidades.push('')
      this.cursoVersionData.habilidades = this.habilidades

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row_habilidades[0].offsetHeight)
      })
    },
    removeItemHabilidad(index) {
      this.habilidades.splice(index, 1)
      this.cursoVersionData.habilidades = this.habilidades
      this.trTrimHeight(this.$refs.row_habilidades[0].offsetHeight)
    },
    initTrHeightHabilidad() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.ref_habilidades.scrollHeight)
      })
    },
    // requisitos
    addRequisitoNuevo() {
      this.requisitos.push('')
      this.cursoVersionData.requisitos = this.requisitos
      // this.cursoVersionData.requisitos.push("")

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row_requisitos[0].offsetHeight)
      })
    },
    removeItemRequisito(index) {
      this.requisitos.splice(index, 1)
      this.cursoVersionData.requisitos = this.requisitos
      this.trTrimHeight(this.$refs.row_requisitos[0].offsetHeight)
    },
    initTrHeightRequisito() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.ref_requisitos.scrollHeight)
      })
    },
    // dias
    addDiaNuevo() {
      this.dias.push('')
      this.cursoVersionData.dias = this.dias
      // this.cursoVersionData.requisitos.push("")

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row_dias[0].offsetHeight)
      })
    },
    removeItemDia(index) {
      this.dias.splice(index, 1)
      this.cursoVersionData.dias = this.dias
      this.trTrimHeight(this.$refs.row_dias[0].offsetHeight)
    },
    initTrHeightDia() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.ref_dias.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

/*
.repeater-horario {
  // overflow: hidden;
  // transition: .35s height;
} */
</style>
